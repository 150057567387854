.App {
  text-align: center;
  font-family: 'Raleway', sans-serif;
}

.App-text {
  font-weight: 900;
}

@media (prefers-reduced-motion: no-preference) {
  .App-text {
    animation: App-text-var infinite 2s cubic-bezier(0.4,0,0.2,1);
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: grid;
  place-items: center;
  font-size: calc(10px + 1.5vmin);
  color: white;
}

@keyframes App-text-var {
  0% {
    font-weight: 200;
  }
  50% {
    font-weight: 900;
  }
  100% {
    font-weight: 200;
  }
}
